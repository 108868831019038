import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ChevronRight from '@material-ui/icons/ChevronRight';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import StarBorder from '@material-ui/icons/StarBorder';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '100%',
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//       minWidth: 40,
//     },
//     nested: {
//       paddingLeft: theme.spacing(4),
//     },
//     listItemText: {
//         paddingLeft: 0,
//         fontSize: '1rem',
//         primary:{
//             fontSize: '1rem',
//         },
//         secondary:{
//             fontSize: '1rem',
//         }
//     },
//     sidebarIsOpen: {
//         paddingLeft: 25,
//         transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
//     },
//     sidebarIsClosed: {
//         paddingLeft: 0,
//         transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
//     },

//     icon:{
//         minWidth: 40,
//     }
    
//   }));

const styles = {
    listItem: {
        paddingLeft: '1rem',
        // fontSize: '1rem',
        color: 'white',
    },
    listItemText: {
        paddingLeft: 2,
        fontSize: '1rem',
        color: 'white',

    },
    icon:{
        minWidth: 36,
        color: 'white', 
    },
    sidebarIsOpen: {
        paddingLeft: 50,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
};


const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    classes,
    icon,
    children,
    translate,
}) => {
    // const classes = useStyles();
return(
    <Fragment>
        <ListItem
            // dense
            button
            onClick={handleToggle}
            className={classes.listItem}
        >
            {/* <ListItemIcon className={classes.icon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon> */}
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText
                inset
                classes={{secondary:classes.listItemText}}
                primary={isOpen ? translate(name) : ''}
                secondary={isOpen ? '' : translate(name)}
                className={classes.listItemText}
                
            />
            {isOpen ? <ExpandLess /> : <ExpandMore color='##eeeeee' />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List
                // dense
                component="div"
                disablePadding
                className={
                    sidebarIsOpen
                        ? classes.sidebarIsOpen
                        : classes.sidebarIsClosed
                }
            >
                {children}
          
            </List>
            {/* <Divider /> */}
        </Collapse>
    </Fragment>
)};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(SubMenu);
