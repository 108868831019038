
import React, {Link, Component} from 'react';
import { Pagination, Labeled, ReferenceArrayField, SingleFieldList,ChipField, ReferenceField, DateField, ReferenceManyField, Datagrid,TextField, Show,useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import {DocApproveButton, WalletResetButton, DocRejectButton, DeleteButton, AvatarButton} from '../../control/button/index';

import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {DataStatusField, DocStatusField, OrderStatusField, FormattedDateField,DriverStatusField,  ActivityTypeField, AvatarField, FullNameClientField,
    ValidateField, ValidityDateField, DriverCompanyTypeField, FileCountField, ClientDataField, PhotoField, DocNameField, DocStatusReasonField} from '../../control/field/index'
import {VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton, 
    VehicleApproveButton, VehicleRejectButton, 
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton} from '../../control/button/index'

import {Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant'
import {BACKEND_FILE_URL} from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040',},
    
    colorPrimary: {color:'#c30040',},
    
    noColorLabel: {paddingLeft: 50,  
    },

    colorValue: {color: '#c30040',paddingLeft:5},

    supplementText: {color: '#c30040',},

    noHeader:{display:'none',
    // height:'1px'
    },

    image:{width:'200px',height:'200px', borderRadius: '50%'},

    
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};





const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {typeof data != 'undefined' &&  typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        
        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées"/>
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath}/>
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};

const AvatarChangeButton = ({ record, basePath, resource}) => {
    return <AvatarButton basePath={basePath} record={record} resource={resource}/>;
}

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function(file, index){
        if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
    , files);

    if(files.length === 0)
        return <div>
        <ValidityDateField record={record}/>
        <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;
    
    return(
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br/>
            <div style={styles.images}>        
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel}/>
                })}
            </div>
            <ValidityDateField record={record}/>
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record}/><DocApproveButton record={record}/></div>

        </div>
    )
};

const VehicleDateField = ({ record = {}, label}) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br/>
            <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label=""  locales="fr-FR"/> : '--'}
            <VehicleDateEditButton record={record}/>
        </Typography><br/>
    </div> : '';
}
// className={classes.noColorLabel}
const BooleanField = ({ record = {}, source,  label}) => {
    const classes = useStyles();
    const value = record[source] === "1" ? "Oui" : "Non";
    return <div>
            <Typography variant="body2">
            {value}€
        </Typography></div>;
    
}

BooleanField.defaultProps = {
    addLabel: true,
};

const WalletBalanceField = ({ record = {wallet:{balance: 0}}}) => {
    const classes = useStyles();
    const value = record.wallet.balance;
    return <div>
            <Typography variant="body1" style={{ fontWeight: 600, fontSize:15 }}>
            PORTEFEUILLE : {value}€
        </Typography><WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/></div>;
    
}

WalletBalanceField.defaultProps = {
    addLabel: false,
};

const WALLET_ACTION_END_CHARGE = 1;
const WALLET_ACTION_CANCEL_CHARGE = 2;
const WALLET_ACTION_CREATE_CHARGE = 3;
const WALLET_ACTION_TIPS = 4;
const WALLET_ACTION_RESET = 5;

const WalletActionField = ({ record = {action: 0}}) => {
    const classes = useStyles();
    let text = "";
    if(record.action == WALLET_ACTION_END_CHARGE) text = "Frais de la course";
    else
    if(record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
    else
    if(record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
    else
    if(record.action == WALLET_ACTION_TIPS) text = "Pourboire";
    else
    if(record.action == WALLET_ACTION_RESET) text = "Remise à 0";
    return <div>
            <Typography variant="body2">
            {text}
        </Typography></div>;
    
}

WalletActionField.defaultProps = {
    addLabel: false,
};


// const AvatarChangeButton =  ({ record = {}, source,  label}) => {
//     return <AvatarButton user={record}/>;
// }

// AvatarChangeButton.defaultProps = {
//     addLabel: false,
// };


const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var transportInsuranceCertUrl = record.transport_insurance_cert_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
    if(transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
    transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;
    var greenCardCertUrl = record.green_card_url;
        if(greenCardCertUrl !== '' && !greenCardCertUrl.startsWith("http://") && !greenCardCertUrl.startsWith("https://"))
        greenCardCertUrl = BACKEND_FILE_URL + greenCardCertUrl;
    var grayCardUrl = record.gray_card_url;
    if(grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;
    
    var frontImageUrl = record.front_image_url;
    if(frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

        var rearImageUrl = record.rear_image_url;
    if(rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
    rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

    var luggageBoxUrl = record.luggage_box_url;
    if(luggageBoxUrl !== '' && !luggageBoxUrl.startsWith("http://") && !luggageBoxUrl.startsWith("https://"))
        luggageBoxUrl = BACKEND_FILE_URL + luggageBoxUrl;

    var wholeImageUrl = record.whole_image_url;
    if(wholeImageUrl !== '' && !wholeImageUrl.startsWith("http://") && !wholeImageUrl.startsWith("https://"))
        wholeImageUrl = BACKEND_FILE_URL + wholeImageUrl;

        var insideImageUrl = record.inside_image_url;
        if(insideImageUrl !== '' && !insideImageUrl.startsWith("http://") && !insideImageUrl.startsWith("https://"))
            insideImageUrl = BACKEND_FILE_URL + insideImageUrl;
    
        var files = [];
        files[0] = {file_url: greenCardCertUrl, name: 'Carte verte asurance'};
        files[1] = {file_url: transportInsuranceCertUrl, name: "Attestation d'assurance avec plaque du véhicule"};
        files[2] = {file_url: grayCardUrl, name: 'Carte grise ou contrat de location'};
        files[3] = {file_url: frontImageUrl, name: 'Photo du véhicule face avant plaque visible'};
        files[4] = {file_url: rearImageUrl, name: 'Photo du véhicule face arrière plaque visible'};
        files[5] = {file_url: wholeImageUrl, name: 'Photo du véhicule en largeur mode paysage'};
        files[6] = {file_url: insideImageUrl, name: 'Photo intérieure arrière du véhicule'};
        files[7] = {file_url: luggageBoxUrl, name: 'Photo du coffre'};

    
    return(
       
            <div style={styles.flexColumn}>
                <span>Couleur : {record['color']}</span><br/>
                <span>Nombre de siège passager max. : {record['seats']}</span><br/>
                <span>Nombre de bagage max. : {record['luggages']}</span><br/>
                <span>Litrage du coffre : {record['luggage_volume']}</span><br/>
                
                <span>Motorisation (Cheveaux fiscaux) : {record['horse_power']}</span><br/>
                <div style={styles.images}>        
                    {files.map(file => {
                        return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/>
                    })}
                </div>
                {/* <VehicleDateField record={record}/> */}
                {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
            </div>
    );
};

const CoeffFields = ({ record }) => {
    // const classes = useStyles();
    // console.log("Vehicle record " + JSON.stringify(record));
    if(record === null || record === 'undefined') return <div/>;
    return(
       
            <div style={styles.flexColumn}>
                <br/>
                <p><span style={{'font-size':'14px'}}>&emsp;Coefficient au prix de base : {record['coef_base']}</span></p>
                <p><span style={{'font-size':'14px'}}>&emsp;Coefficient au prix par km : {record['coef_km']}</span></p>
                <p><span style={{'font-size':'14px'}}>&emsp;Coefficient au prix par minut {record['coef_minute']}</span></p>
                <br/>
              
            </div>
    );
};


const ContentTextField = ({ source, record = {}, label}) => {
    if(typeof(record) !== 'undefined'){
        var content = record['content'];
        if(typeof(content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig,"");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}
    
ContentTextField.defaultProps = {
    addLabel: true,
};

const CompanyField = ({ source, record = {}, label}) => {
    const classes = useStyles();
    if(typeof(record) !== 'undefined'){
        var userType = record['user_type'];
        if(typeof(userType) !== 'undefined' && userType !== null){
            if(userType === USER_TYPE_COMPANY_AND_DRIVER){
                return <div>
                <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Société</Typography>
            <Labeled label="Nom de société">
        <Typography variant="body2">
            {record.company_name}
        </Typography>
        </Labeled>
        <Labeled label="Adresse du siègle social">
        <Typography variant="body2">
            {record.company_address}
        </Typography>
        </Labeled>

        <Labeled label="Code postal">
        <Typography variant="body2">
            {record.postal_code}
        </Typography>
        </Labeled>

        <Labeled label="Ville">
        <Typography variant="body2">
            {record.city}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="IBAN">
        <Typography variant="body2">
            {record.iban}
        </Typography>
        </Labeled>
        <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Représentant légal</Typography>
        <Labeled label="Nom">
        <Typography variant="body2">
            {record.manager !== null && record.manager.last_name !== null ? record.manager.last_name: ""}
        </Typography>
        </Labeled>

        <Labeled label="Prénom">
        <Typography variant="body2">
            {record.manager !== null && record.manager.first_name !== null ? record.manager.first_name : ""}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="Date de naissance">
        <Typography variant="body2">
            {record.manager !== null && record.manager.birth_date !== null ? record.manager.birth_date : ""}
        </Typography>
        </Labeled>

        <br/>
        <Labeled label="Adresse">
        <Typography variant="body2">
            {record.manager !== null && record.manager.address !== null ? record.manager.address : ""}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="Pays">
        <Typography variant="body2">
            {record.manager !== null && record.manager.country_name !== null ? record.manager.country_name : "Belgique"}
        </Typography>
        </Labeled>

              </div>;
            }

            return <div>

    <Labeled label="Date de naissance">
        <Typography variant="body2">
            {record.birth_date}
        </Typography>
        </Labeled>

        <Labeled label="Pays">
        <Typography variant="body2">
            {record.country_name}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="Adresse">
        <Typography variant="body2">
            {record.address}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="Code postal">
        <Typography variant="body2">
            {record.postal_code}
        </Typography>
        </Labeled>

        <Labeled label="Ville">
        <Typography variant="body2">
            {record.city}
        </Typography>
        </Labeled>
        <br/>
        <Labeled label="IBAN">
        <Typography variant="body2">
            {record.iban}
        </Typography>
        </Labeled>
            </div>;
        }
            
    }
    return <span></span>
}
    
CompanyField.defaultProps = {
    addLabel: false,
};

const CardNumberField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>    
    // return <div></div>
}

const ExpiryField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
//    console.log('Card ' + JSON.stringify(record));
  //  console.log('Car data ' + JSON.stringify(record.data));
    if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
        return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>    
    return <div></div>
}

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

// import { withStyles } from "material-ui/styles";



const ShowDriver = ({staticContext, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();
  
    return(
        
    <Show component="div" title={<Title />} actions={<ShowActions/>} {...props}>
            <TabbedShowLayout redirect="list">
            <Tab label="Profil">
            {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Profil</Typography> */}
            <DriverStatusField source="online_status" label="Statut"/>
                <AvatarField source="photo_url" label="" size='200'/>
                <TextField source="user_code" label="Identifiant parrain"/>
                <TextField source="company_name" label="Nom de l'entreprise"/>
                {/* <DriverCompanyTypeField label="Type de chauffeur"/> */}
                {/* <PhotoField source="photo_url" label=""/> */}
                {/* <div style={{"width":"60px" , "height":"60px"}}> */}
                {/* <ImageField source="photo_url" label="" className="avatar"/> */}
                {/* </div> */}
                {/* <ImageField source="photo_url" label="" style={{"width":"60px" , "height":"60px", "border-radius": "50%"}}/> */}
                {/* <AvatarChangeButton/> */}
                {/* <TextField source="user_code" label="Identifiant unique"/> */}
                {/* <TextField source="id" label="ID"/> */}
                {/* <GenderField label={translate('civility')}/> */}
                {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                <div/>
                <TextField source="last_name" label="Nom" className={classes.inlineBlock}/>
                <TextField source="first_name" label="Prénom " className={classes.inlineBlock}/>
                <div/>
                
                <div/>
                <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
                <TextField source="email" label = "Email" className={classes.inlineBlock}/>
                <div/>
                <TextField source="address" label="Adresse" className={classes.inlineBlock}/>
            <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
            <TextField source="city" label="Ville" className={classes.inlineBlock}/>
            <TextField source="country_name" label="Pays" className={classes.inlineBlock}/>
            <div/>
            <TextField source="siren_number" label="Numéro SIREN"/>
            <TextField source="iban" label="IBAN"/>
            
                {/* <ActivityTypeField source="activity_type" label = "Type d'activité"/>
                <ValidateField source="animal_accepted" label="Animal accepté"/> */}
                {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}
                {/* <br/> */}
                {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                
                {/* <br/>
                <Divider/> */}
                {/* <br/> */}
                {/* <Typography variant="body2">État</Typography> */}
               
            {/* </Tab> */}

            {/* <Tab label="Infos de société"> */}
                {/* <AvatarField source="photo_url" label="" size='80'/> */}
                {/* <PhotoField source="photo_url" label=""/>
                <TextField source="id" label="ID"/> */}
                {/* <GenderField label={translate('civility')}/> */}
                {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField>
                <div/> */}
                {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Société</Typography> */}
                
                
                {/* <TextField source="company_name" label="Nom de société"/>
                <TextField source="company_address" label="Adresse du siège social "/> */}
                {/* <CompanyField/> */}
                <Divider/>
                <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
                <TextField source="activation_code" label="Code d'activation"/>
                <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/>
                {/* <TextField source="postal_code" label="Code postal"  className={classes.inlineBlock}/>
                <TextField source="city" label="Ville"  className={classes.inlineBlock}/> */}
                
                {/* <TextField source="phone" label = "Téléphone"/> */}
                {/* <TextField source="iban" label = "IBAN"/> */}
                {/* <TextField source="siren_number" label = "Nº SRIEN"/> */}
                {/* <TextField source="vat_no" label = "Nº de TVA intracommunautaire"/> */}
                <br/>
                {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'none', fontSize:13 }}>Représentant légal</Typography>
                <TextField source="manager.last_name" label = "Nom" className={classes.inlineBlock}/>
                <TextField source="manager.first_name" label = "Prénom" className={classes.inlineBlock}/>
                <FormattedDateField source="manager.birth_date" label="Date de naissance"  locales="fr-FR"/>
                <TextField source="manager.address" label = "Adresse"/>
                <TextField source="manager.postal_code" label = "Pays"/>
                <TextField source="manager.city" label = "Pays"/> */}

                {/* <TextField source="email" label = "Email" className={classes.inlineBlock}/> */}
                {/* <br/> */}
                {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                {/* <br/>
                <Divider/> */}
                {/* <br/> */}
                {/* <Typography variant="body2">État</Typography> */}
                {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/> */}
            </Tab>

            {/* <Tab label="Paramètres">
                <BooleanField source="settings.3rd_payment" label="Tiers payant"/>
                <DriverSettingsButton/>
            </Tab> */}
            
            <Tab label="Documents d'activité">
                <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                        <DocNameField label="Nom du document"/>
                        <FileCountField label="Fichiers"/>
                        <DocStatusField source="status" label="État"/>
                        <DocStatusReasonField label="Motif de refus"/>
                        {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                        {/* <OpenFileButton/> */}
                        
                    </Datagrid>
                </ReferenceManyField>
                {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                {/* <FileViewer source="files" src='url' /> */}
            </Tab>
            <Tab label="Véhicules">
                <ReferenceManyField pagination={<Pagination />} reference="vehicle" target="driver_id" addLabel={false}>
                    <Datagrid expand={<VehicleContentPanel/>}>
                        <TextField source="plate_number" label="Immatriculation"/>
                        <TextField source="brand_name" label="Marque"/>
                        <TextField source="model_name" label="Modèle"/>
                        {/* <TextField source="vehicle_type_name" label="Type"/> */}
                        {/* <TextField source="color" label="Couleur"/> */}
                        <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                        <FormattedDateField source="circulation_date" label="Date de première mise en circulation"  locales="fr-FR"/>
                        <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/>
                        <ValidateField source="is_default" label="Par défaut"/>
                        
                        <DataStatusField source="status" label="État"/>
                        <ValidateField source="is_locked" label="Vérrouillé"/>
                        
                        {/* <VehicleDateEditButton/> */}
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Cartes de paiement">
                <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
                    <Datagrid>
                        <CardNumberField label='Numéro de la carte'/>  
                        <ExpiryField label="Expiration"/>
                        <TextField source="data.card_type" label="Type"/>
                        <ValidateField source="is_default" label="Par défaut"/>      
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Abonnements">
            <br/>
            <Typography variant="body2"><b>Coefficients appliqués</b></Typography>
                {/* <TextField source="coef_base" label="Coefficient au prix de base"/>
                <TextField source="coef_km" label="Coefficient au prix par km"/>
                <TextField source="coef_minute" label="Coefficient au prix par minute"/> */}
                <br/>
                <CoeffFields/>
                <Typography variant="body2"><b>Abonnements</b></Typography>
                {/* <br/> */}
                <ReferenceManyField pagination={<Pagination />} reference="subscription" target="user_id" addLabel={false}>
                    <Datagrid>
                        <FormattedDateField label='De' source="from_date"/>  
                        <FormattedDateField label="À" source="to_date"/>
                        <TextField source="amount" label="Montant"/>
                        <ValidateField source="status" label="Payé ?"/>      
                    </Datagrid>
                </ReferenceManyField>
                <Typography variant="body2"><b>Chauffeurs partenaires</b></Typography>
                {/* <br/> */}
                <ReferenceManyField pagination={<Pagination />} reference="partner_driver" target="driver_id" addLabel={false}>
                    <Datagrid>
                    <TextField source="user_code" label="Identifiant parrain"/>
                    <TextField source="last_name" label="Nom"/>
                    <TextField source="first_name" label="Prénom"/>
                    <TextField source="mobile" label="Mobile"/>
                    <TextField source="email" label="Email"/>
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <DriverStatusField source="online_status" label="Statut"/> */}
                    {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                    {/* <ValidateField source="activated" label='Activé'/>
                    <ValidateField source="validated" label='Validé'/>*/}
                    <ValidateField source="locked" label='Verrouillé'/> 
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Commandes">
                <ReferenceManyField pagination={<Pagination />} reference="order_driver" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="show">
                        <TextField source="create_time" label="Date\heure"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <FullNameClientField label="Client"/>
                        {/* <ClientDataField source="last_name" label="Nom du client"/> */}
                        {/* <ClientDataField source="first_name" label="Prénom du client"/> */}
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <TextField source="total" label="Prix(€)"/>
                        <OrderStatusField source="status" label = "État"/>
                        {/* <TextField source="rating_on_driver" label="Note attribué par le client"/> */}
                        </Datagrid>
                    </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>

       
    </Show>
    );
};


export default ShowDriver;

   {/* <Tab label="Informations de la société">
                <UserTypeField label="Type d'entreprise"/>
                <TextField source="company_name" label = "Nom"/>
                <TextField source="company_address" label = "Adresse"/>
                <div/>
                <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
                <TextField source="city" label="Ville" className={classes.inlineBlock}/>                
            </Tab> */}