
import React from 'react';
import {AutocompleteInput, SelectInput, ReferenceInput, TextInput, Create, SimpleForm, useTranslate} from 'react-admin';
import {
    required,
    number,
} from 'react-admin';

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes} from '../../util/constant'
import {activityTypes} from '../../util/variant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];


// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.location.create')}</span>;
};

const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CreatePriceRoute = props => {
    const classes = useStyles();
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
            reference="vehicle_type" validate={required()}>
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput>
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/> */}

            <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/>
            <TextInput source="from_postal_codes" label = "Codes postaux du départ" fullWidth/>
            <TextInput source="from_keywords" label = "Mots clefs dans l'adresse du départ" fullWidth/>
            <TextInput source="to_postal_codes" label = "Codes postaux de la destination" fullWidth/>

            
            <TextInput source="to_keywords" label = "Mots clefs dans l'adresse de la destination" fullWidth/>

            {/* <ReferenceInput label="Adresse de départ" source="from_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput>

            <ReferenceInput label="Adresse d'arrivée" source="to_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput> */}

            <TextInput source="per_package" label = "Prix(€)" validate={validatePriceAdditional}/>
            
        </SimpleForm>
    </Create>
)};

export default CreatePriceRoute;

{/* options={{ format: 'dd/MM/yyyy' }  */}