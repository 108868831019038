
// in ./MyLayout.js
import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import NewAppBar from './new-app-bar.js';
import Menu from './menu.js'
import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme() => ({ 
//     drawer: {
//         backgroundColor:'#FF0000'
//     },
// }));

const useStyles = makeStyles({
    // drawer: {
        backgroundColor:'#FF0000'
    // },
});


const NewSidebar = props => {
    const classes = useStyles();
return <div style={{'background': "#001b4c"}}><Sidebar {...props} size={300} /></div>;}
const NewLayout = props => <div style={{'background': "#001b4c"}}><Layout {...props} sidebar={NewSidebar} appBar={NewAppBar} menu={Menu}/></div>;

export default NewLayout;