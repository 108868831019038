
import React from 'react';
import { TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import {CheckboxGroupInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput,  
    Create, number} 
from 'react-admin';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';

// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {generateRandom, validateTimeFormat} from "../../util/function"
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import {DeleteButton, PublishButton} from '../../control/button/index';
import {ValidateField} from '../../control/field/index';

import {TRUE, FALSE, unitTypes} from '../../util/constant'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const validateTime = [required(), validateTimeFormat];

const Title = ({ record }) => {
    // var string = "";
    // if(record){
    //     const vehicleTypeName = record ? record.vehicle_type_name : '';
    //     const orderType = record ? record.order_type : 0;
    //     if(orderType == OrderType.IMMEDIATE)
    //         string = vehicleTypeName + " - Immédiate";
    //     else
    //         string = vehicleTypeName + " - Réservation à l'avance";
    // }
    const translate = useTranslate();
    return <span>{translate('resources.bonus_client.show')}</span>;
    
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];


const EditPriceRoute = ({permissions, ...props })  => {
    const classes = useStyles();
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="list" 
                toolbar={<CustomToolbar/>}>
<MuiPickersUtilsProvider utils={MomentUtils}>

<DateInput source="begin_date" label="Date de début de validité"
    options={{ format: 'DD/MM/YYYY',timeZone: 'UTC' }} 
        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
        validate={required()}/>
    {/* <DateTimeInput source="begin_date" label="Date de début" 
    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} /> */}
    <TextInput source='b_time' label="Heure de début" validate={validateTime} />

<DateInput source="end_date" label="Date de fin  de validité" 
    options={{ format: 'DD/MM/YYYY', timeZone: 'UTC', ampm: false, clearable: true }} validate={required()}/>
    <TextInput source='e_time' label="Heure de fin" validate={validateTime} />
</MuiPickersUtilsProvider>

<TextInput source="keywords" label = "Mots clefs dans l'adresse de destination" style={{'width':'600px'}}/>
<TextInput source="order_count" label = "Nombre de commandes" validate={validatePriceAdditional}/>
<TextInput source="points" label = "Points gagnés" validate={validatePriceAdditional}/>
        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
            reference="vehicle_type" validate={required()}>
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput> */}
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/>
             */}
            {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/>
            <TextInput source="from_postal_codes" label = "Codes postaux du départ" style={{'width':'600px'}}/>
            

            <TextInput source="from_keywords" label = "Mots clefs dans l'adresse du départ" style={{'width':'600px'}}/>
            <TextInput source="to_postal_codes" label = "Codes postaux de la destination" style={{'width':'600px'}}/>
            <TextInput source="to_keywords" label = "Mots clefs dans l'adresse de la destination" style={{'width':'600px'}}/> */}

            {/* <ReferenceInput label="Adresse de départ" source="from_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput>

            <ReferenceInput label="Adresse d'arrivée" source="to_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput> */}

            {/* <TextInput source="per_package" label = "Prix(€)" validate={validatePriceAdditional}/> */}
        </SimpleForm>
    </Edit>
)};

export default EditPriceRoute;