
import React from 'react';
import {AutocompleteInput, SelectInput, ReferenceInput, TextInput, Create, SimpleForm, useTranslate} from 'react-admin';
import {
    required,
    number,
} from 'react-admin';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes} from '../../util/constant'
import {activityTypes} from '../../util/variant'
import { validateTimeFormat } from '../../util/function';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const validateTime = [required(), validateTimeFormat];

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];


// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.bonus_driver.create')}</span>;
};

const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CreatePriceRoute = props => {
    const classes = useStyles();
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
            reference="vehicle_type" validate={required()}>
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput> */}
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/> */}

            {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/> */}
            {/* <TextInput source="from_postal_codes" label = "Codes postaux du départ" style={{'width':'600px'}}/> */}
            <MuiPickersUtilsProvider utils={MomentUtils}>

                {/* <DateTimeInput source="begin_time" label="Date de début de validité"
                    options={{ format: 'DD/MM/YYYY: HH:mm' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()}/>
                
                <DateTimeInput source="end_time" label="Date de fin de validité" 
                    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} validate={required()}/> */}
                    <DateInput source="begin_date" label="Date de début"
                    options={{ format: 'DD/MM/YYYY',timeZone: 'UTC' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} formClassName={classes.inlineBlock}/>
                    {/* <DateTimeInput source="begin_date" label="Date de début" 
                    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} /> */}
                    <TextInput source='begin_time' label="Heure de début" validate={validateTime} formClassName={classes.inlineBlock}/>
<div/>
                <DateInput source="end_date" label="Date de fin" 
                    options={{ format: 'DD/MM/YYYY', timeZone: 'UTC', ampm: false, clearable: true }} validate={required()} formClassName={classes.inlineBlock}/>
                    <TextInput source='end_time' label="Heure de fin" validate={validateTime} formClassName={classes.inlineBlock}/>
            </MuiPickersUtilsProvider>

            {/* <TextInput source="keywords" label = "Mots clefs dans l'adresse de destination" style={{'width':'600px'}}/>
            <TextInput source="order_count" label = "Nombre de commandes" validate={validatePriceAdditional}/> */}
            <TextInput source="points" label = "Points gagnés" validate={validatePriceAdditional}/>
            {/* <TextInput source="to_postal_codes" label = "Codes postaux de la destination" style={{'width':'600px'}}/>

            
            <TextInput source="to_keywords" label = "Mots clefs dans l'adresse de la destination" style={{'width':'600px'}}/> */}

            {/* <ReferenceInput label="Adresse de départ" source="from_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput>

            <ReferenceInput label="Adresse d'arrivée" source="to_id" perPage={99999}
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput> */}

            
            
        </SimpleForm>
    </Create>
)};

export default CreatePriceRoute;

{/* options={{ format: 'dd/MM/yyyy' }  */}