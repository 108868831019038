import React, {Fragment} from 'react';
import { ReferenceField, List, Datagrid, TextField, ReferenceInput, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {orderTypes} from '../../util/constant'
import {OrderTypeField, OrderTypeFilter, OrderActivityTypeField} from '../../control/field/index'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
    largeWidth: {width: '200px'},
});

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = withStyles({name: {width: 200}})(({ permissions, ...props })  => {
    const classes = useStyles();
    return (<Filter {...props}>
    
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        <SelectInput label="Type de commande" source="order_type" alwaysOn choices={orderTypes} 
            optionText="name" emptyText="Tous les types" emptyValue='-1' 
            initialValue='-1'
            />
        <ReferenceInput source="vehicle_type_id" perPage={99999}  alwaysOn
            reference="vehicle_type" label="Type de véhicule">
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* <ReferenceInput source="vehicle_class_id" perPage={99999} alwaysOn className={classes.largeWidth}
            reference="vehicle_class" label="Gamme de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput> */}
        
    </Filter>
)});

const ListPrice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            {/* <TextField source="vehicle_type_name" label = "Type de véhicule"/> */}
            {/* <OrderActivityTypeField label="Type d'activité"/> */}
            <OrderTypeField source="order_type" label="Type de commande"/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                    <TextField source="name" />
                </ReferenceField> */}
                <TextField source="base" label = "Base(€)"/>
            <TextField source="min" label="Minimum (€)"/>
            {/* <TextField source="min_sub" label="Minimum abonné(€)"/> */}
            <TextField source="per_km" label="Par km (€)"/>
            {/* <TextField source="per_km_sub" label="Par km abonné (€)"/> */}
            <TextField source="per_minute" label="Par minute (€)"/>
            {/* <TextField source="per_minute_sub" label="Par minute abonné (€)"/> */}
            {/* <TextField source="wait" label="Frais d'attente (€/minute)"/> */}
            {/* <TextField source="free_wait_time" label="Attente gratuite (minute)"/> */}
            {/* <TextField source="wait" label="Attente(€/minute)"/>
            <TextField source="markup" label="Supplément(€)"/> */}
            {/* <TextField source="quantity_markup" label="Supplément au dela 3 passagers(€/passager)"/> */}
        </Datagrid>
    </List>
);

export default ListPrice;

